<template>
    <div class="item-wrap-3">
        <rs-tabs v-model="activeName" @tab-click="handleClick" >
            <rs-tab-pane label="本月用水量（m³）" name="水能">
            </rs-tab-pane>
            <rs-tab-pane label="本月用电量（kwh）" name="电能">
            </rs-tab-pane>
            <rs-tab-pane label="本月燃气量（m³）" name="天燃气">
            </rs-tab-pane>
        </rs-tabs>
        <div class="count-main">
            <div class="flex" v-for="item in data" >
                <div class="count_title">{{item[0]}}</div>
                <div class="cost-value">{{$moneyShow(item[1])}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/api/reportServer'
    export default {
        name: "card_chart.vue",
        props:['mallCode'],
        data(){
            return{
                activeName:'水能',
                data:[]
            }
        },
        mounted(){

        },
        methods:{
            getData(){
                Api.getReportData('53926a9830f844e4a638d2cb193d69d2',{mallCode:this.mallCode,energyType:this.activeName}).then(res => {
                    this.data = res.data.data.dataset
                })
            },
            handleClick(){
                this.getData()
            }
        },
        watch:{
            mallCode:{
                handler(val){
                    if(val){
                        this.getData()
                    }
                },
                immediate:true,
                deep:true
            }
        }
    }
</script>

<style lang="less" scoped>
    .item-wrap-3 {
        flex: 1;
    }
    .item-wrap-3 {
        .count-main {
            // padding-left: 28px;
            display: flex;
            height: calc(100% - 52px);
            align-items: center;
            padding: 10px;
            margin-top:5px;
            justify-content: space-around;
            // flex-direction: column;
            .flex {
                // flex: 1;
                text-align:center;
                cursor: pointer;
                flex-direction: column;
                min-width: 120px;
                .count-label {
                    display: flex;
                    align-items: center;
                    // width: 146px;
                    margin-top: 8px;
                    .item-title {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #999999;
                        text-align: center;
                    }
                }
                .cost-value {
                    font-family: PingFangSC-Medium;
                    font-size: 28px;
                    color: #333333;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    &:hover {
                        color: #0091FF;
                    }
                }
                .item-img-icon {
                    max-height: 24px;
                    margin-right: 10px;
                }
            }}}
    .count-title {
        height: 52px;
        text-align: left;
        line-height: 52px;
        padding-left: 20px;
        padding-right: 14px;
        border-bottom: 1.5px solid #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
</style>
