var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-wrap-3" }, [
    _c("div", { staticClass: "count-title" }, [
      _vm._v(_vm._s(_vm.title)),
      _c(
        "span",
        {
          staticStyle: {
            "font-size": "14px",
            color: "#1890FF",
            float: "right",
            cursor: "pointer",
          },
          on: {
            click: function ($event) {
              return _vm.jumpUrl("公告", "/operation/#/receivedAnnouncement")
            },
          },
        },
        [_vm._v("查看详情")]
      ),
    ]),
    _c("div", { staticClass: "count-main" }, [
      _c(
        "ul",
        _vm._l(_vm.list, function (item) {
          return _c("li", [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(item[1]))]),
            _c("span", { staticClass: "time" }, [_vm._v(_vm._s(item[2]))]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }