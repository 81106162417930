<template>
    <div :id="id" :style="{height:height}"></div>
</template>

<script>
    export default {
        name: "echarts_components.vue",
        props:['options','height','id'],
        methods:{
            drawLine(){
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById(this.id))
                // 绘制图表
                myChart.clear();
                myChart.setOption(this.options || {});
            },
        },
        mounted(){
            this.drawLine()
        },
        watch:{
            options(newVal,oldVal){
                if(newVal != oldVal){
                    this.drawLine()
                }
            }
        }
    }
</script>

<style scoped>

</style>