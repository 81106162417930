<template>
    <div class="contain">
        <rs-tabs v-model="activeName" @tab-click="handleClick" >
            <rs-tab-pane label="昨日销售明细" name="first">
            </rs-tab-pane>
            <rs-tab-pane label="本月销售明细" name="second">
            </rs-tab-pane>
            <rs-radio-group v-model="radio" @change="chooseType" size="mini" style="position: absolute;width:35%;top:0px;left:10px;z-index:9;">
                <rs-radio-button label="店铺"></rs-radio-button>
                <rs-radio-button label="业态"></rs-radio-button>
            </rs-radio-group>
            <div v-if="!option.dataset || option.dataset.source.length === 0" class="noMsgIconDiv">
                <img src="../../assets/images/msgIcon/noMsgIcon.png">
                <span>暂无数据！</span>
            </div>
            <echartsBra v-else :id="id" :options="option" height="311px" :style="{width: '100%', height: '311px'}"></echartsBra>
        </rs-tabs>
    </div>
</template>

<script>
    import echartsBra from '@/components/echarts/echarts_components'
    import Api from '@/api/reportServer'
    export default {
        name: "chart_block",
        components: {echartsBra},
        props:["mallCode"],
        data(){
            return{
                option:'',
                radio:'店铺',
                activeName:'first',
                id:''
            }
        },
        mounted(){
            this.getData()
        },
        methods:{
            handleClick(){
                if(this.mallCode){
                    this.getData()
                }
            },
            chooseType(){
                if(this.mallCode){
                    this.getData()
                }
            },
            getData(){
                if(this.radio == '店铺'){
                    if(this.activeName == 'first'){
                        this.id = 'c3ccecbfb15849f899b4e9a4fb88c586'
                    } else if(this.activeName == 'second'){
                        this.id = 'd12748ae7ac447d58ec50ae42727c782'
                    }
                } else if(this.radio == '业态'){
                    if(this.activeName == 'first'){
                        this.id = 'cc08741d4c4e417ea293e4aac1eca2b4'
                    } else if(this.activeName == 'second'){
                        this.id = 'a00bbc1212a24f76af665104b271086a'
                    }
                }
                Api.getReportData(this.id,{mallCode:this.mallCode}).then(res => {
                    this.title = res.data.data.metaData.title
                    this.option = {
                        dataset: {
                            dimensions:res.data.data.metaData.columnNames,
                            source: res.data.data.dataset
                        },
                        xAxis: {
                            type: 'category',
                            axisTick:{
                                show:false
                            }
                        },
                        yAxis: {
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed'
                                }
                            },
                            axisLine:{
                                show:false
                            },
                            axisTick:{
                                show:false
                            }
                        },
                        series: [
                            {
                                type: 'bar',
                                encode: {
                                    // Map the "amount" column to X axis.
                                    y: res.data.data.chartConfig.yAxis,
                                    // Map the "product" column to Y axis
                                    x: res.data.data.chartConfig.xAxis
                                },
                                barWidth: 30,
                                itemStyle:{
                                    normal:{
                                        color:'#1890FF'
                                    }
                                }
                            }
                        ],
                        grid: {
                            left: '8%',
                            right: '10px',
                            top: '20%',
                            bottom: '20%'
                        },
                        tooltip:{
                            show:true
                        }
                    };
                })
            },
        },
        watch:{
            mallCode(val){
                if(val){
                    this.getData()
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .contain {
        flex: 1;
    }
    .contain:first-child {
        margin-right: 14px;
    }
    .contain {
        .count-main {
            // padding-left: 28px;
            display: flex;
            height: calc(100% - 52px);
            align-items: center;
            padding: 10px;
            margin-top:5px;
            justify-content: space-around;
            // flex-direction: column;
            .flex {
                // flex: 1;
                cursor: pointer;
                flex-direction: column;
                min-width: 120px;
                .count-label {
                    display: flex;
                    align-items: center;
                    // width: 146px;
                    margin-top: 8px;
                    .item-title {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #999999;
                        text-align: center;
                    }
                }
                .cost-value {
                    font-family: PingFangSC-Medium;
                    font-size: 28px;
                    color: #333333;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    &:hover {
                        color: #0091FF;
                    }
                }
                .item-img-icon {
                    max-height: 24px;
                    margin-right: 10px;
                }
            }}}
    .count-title {
        height: 52px;
        text-align: left;
        line-height: 52px;
        padding-left: 20px;
        padding-right: 14px;
        border-bottom: 1.5px solid #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
    .flex-block{
        position: absolute;
        top:5px;
        left:10px;
        right:10px;
        z-index:9;
        display: flex;
        justify-content: space-between;
    }
    .noMsgIconDiv {
        height: 250px;
        position: relative;
    }
    .noMsgIconDiv img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150px;
        height: 150px;
        transform: translate(-50%, -50%);
    }
    .noMsgIconDiv span {
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        letter-spacing: 0;
        text-align: center;
    }
</style>