<template>
    <div class="item-wrap-3">
        <div class="count-title">{{title}}</div>
        <div class="count-main">
            <div :class="params.label[index] =='/'?'flex':'flex hoverblur'" v-for="(item,index) in data" :key="index" @click="handleJump(index,params.label[index])">
                <div class="count_title">{{item[0]}}</div>
                <div class="cost-value">{{item[1]}}</div>
                <div class="count-label"><span class="item-title">{{params.label[index]}}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/api/reportServer'
    export default {
        name: "card_chart.vue",
        props:['mallCode',"id","params","label"],
        data(){
            return{
                data:[],
                title:''
            }
        },
        mounted(){

        },
        methods:{
            getData(){
                Api.getReportData(this.id,{...this.params,mallCode:this.mallCode}).then(res => {
                    this.title = res.data.data.metaData.title
                    this.data = res.data.data.dataset
                })
            },
            handleClick(){
                this.getData()
            },
            handleJump(index,label){  
                if(label=='/') return;
                let name,url
                if(this.id == '07b82bdaa2804c21a30462a92085d670'){//招商合同
                    if(index == 0){
                        name = '招商合同到期'
                        url = `/contract/#/contractList?contractStatus=1&days=${this.params.endDays}&mallCode=${this.mallCode}`
                    }else if(index == 1){
                        name = '招商合同开始'
                        url = `/contract/#/contractList?contractStatus=4&days=${this.params.beginDays}&mallCode=${this.mallCode}`
                    }
                    let params = {
                        type :'jumpUrl',
                        url:name+'&' +url
                    }
                    parent.postMessage(params, '*')
                }else if(this.id == '00e3819c66584ccda5060ed6d87e7272'){ // 多经
                    if(index == 0){
                        name = '招商合同到期'
                        url = `/contract/#/multiContractList?contractStatus=1&days=${this.params.endDays}&diversifyForm=1&mallCode=${this.mallCode}`
                    }else if(index == 1){
                        name = '招商合同开始'
                        url = `/contract/#/multiContractList?contractStatus=4&days=${this.params.beginDays}&diversifyForm=1&mallCode=${this.mallCode}`
                    }
                    let params = {
                        type :'jumpUrl',
                        url:name+'&' +url
                    }
                    parent.postMessage(params, '*')
                }else if(this.id == 'f4e2755396404bafb64eaaf6d37a4191'){ // 广告位
                    if(index == 0){
                        name = '招商合同到期'
                        url = `/contract/#/multiContractList?contractStatus=1&days=${this.params.endDays}&diversifyForm=2,3&mallCode=${this.mallCode}`
                    }else if(index == 1){
                        name = '招商合同开始'
                        url = `/contract/#/multiContractList?contractStatus=4&days=${this.params.beginDays}&diversifyForm=2,3&mallCode=${this.mallCode}`
                    }
                    let params = {
                        type :'jumpUrl',
                        url:name+'&' +url
                    }
                    parent.postMessage(params, '*')
                }
            },
        },
        watch:{
            mallCode(val){
                if(val){
                    // this.getData()
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .item-wrap-3 {
        flex: 1;
        height:100%;
    }
    .item-wrap-3 {
        .count-main {
            height: calc(100% - 52px);            
            .flex {
                // flex: 1;
                text-align:center;
                padding: 10px;
               
                width:50%;
                height: 100%;
                float: left;
                min-width: 120px;
                .count-label {
                    text-align:center;
                    margin-top:8px;
                    // width: 146px;
                    .item-title {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #999999;
                        text-align: center;
                    }
                }
                .cost-value {
                    font-family: PingFangSC-Medium;
                    font-size: 28px;
                    color: #333333;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
                .item-img-icon {
                    max-height: 24px;
                    margin-right: 10px;
                }
                &:hover{
                    background:  rgba(243,251,255,0.50);                    
                }
            }
            .hoverblur{
                 cursor: pointer;
                .cost-value {
                    &:hover{
                        color: #0091FF;
                    }
                }
            }
        }}
    .count-title {
        height: 52px;
        text-align: left;
        line-height: 52px;
        padding-left: 20px;
        padding-right: 14px;
        border-bottom: 1.5px solid #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
    .count_title {
        text-align: center;
        line-height: 35px;
        padding-left: 20px;
        padding-right: 14px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
</style>