<template>
    <div class="item-wrap-3">
        <div class="count-title">{{title}}<span style="font-size: 14px;color: #1890FF; float:right;cursor: pointer" @click="jumpUrl('公告','/operation/#/receivedAnnouncement')">查看详情</span></div>
        <div class="count-main">
            <ul>
                <li v-for="item in list">
                    <span class="title">{{item[1]}}</span>
                    <span class="time">{{item[2]}}</span>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
    import Api from '@/api/reportServer'
    import Utils from '@/utils/utils'
    export default {
        name: "notice_list.vue",
        props:["mallCode"],
        data(){
            return{
                list:[],
                title:'事项公告'
            }
        },
        methods:{
            getData() {
                let postIdStr = []
                if(Utils.getSession('userInfo').stationLoginVoList) {
                    Utils.getSession('userInfo').stationLoginVoList.forEach(item=>{
                        postIdStr.push(item.stationSysCode)
                    })
                }
                if(postIdStr.length > 0){
                    Api.getReportData('081ac653d331403eb654d426a738d6d5', {
                        stationCodes:postIdStr,
                        limit:4
                    }).then(res => {                        
                        this.list = res.data.data.dataset                        
                        this.title = res.data.data.metaData.title
                    })
                }
            },
            jumpUrl(name,url){
                let params = {
                    type :'jumpUrl',
                    url:name+'&' +url
                }
                parent.postMessage(params, '*')
            }
        },
        watch:{
            mallCode(val){
                if(val){
                    this.getData()
                }
            }
        }

    }
</script>

<style lang="less" scoped>
    .item-wrap-3 {
        flex: 1;
    }
    .item-wrap-3 {
    .count-main {
    // padding-left: 28px;
        display: flex;
      
        align-items: center;
        padding: 10px;
        justify-content: space-around;
    // flex-direction: column;
    .flex {
    // flex: 1;
        text-align:center;
        cursor: pointer;
        flex-direction: column;
        min-width: 120px;
    .count-label {
        display: flex;
        align-items: center;
    // width: 146px;
        margin-top: 8px;
    .item-title {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        text-align: center;
    }
    }
    .cost-value {
        font-family: PingFangSC-Medium;
        font-size: 28px;
        color: #333333;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    &:hover {
         color: #0091FF;
     }
    }
    .item-img-icon {
        max-height: 24px;
        margin-right: 10px;
    }
    }}}
    .count-title {
        height: 52px;
        text-align: left;
        line-height: 52px;
        padding-left: 20px;
        padding-right: 14px;
        border-bottom: 1.5px solid #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
    .count-main {
        overflow: auto;
        height: 147px;
        
        ul{
            height:100%;
            width:100%;            
        }
        ul>li{
            &:hover{
                background:  rgba(243,251,255,0.50);
                cursor: pointer;
            }
            height:32px;
            display:flex;
            align-items: center;
            width:100%;
            .title{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #333333;
                display:inline-block;
                width:70%;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            .time{
                font-family: PingFangSC-Medium;
                font-size: 12px;
                color: #999999;
                display:inline-block;
                width:30%;
            }
        }

    }
</style>