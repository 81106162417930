<template>
    <div class="contain">
        <div class="count-title">{{title}}</div>
        <div class="count-main" style="position: relative;">
            <div class="flex-block">
                <rs-radio-group v-if="choose" v-model="radio" size="mini" @change="chooseType">
                    <rs-radio-button label="电能"></rs-radio-button>
                    <rs-radio-button label="水能"></rs-radio-button>
                    <rs-radio-button label="天燃气"></rs-radio-button>
                </rs-radio-group>
                <rs-date-picker
                        size="mini"
                        v-model="year"
                        type="year"
                        style="width:30%;"
                        @change="chooseYear"
                        format="yyyy 年"
                        value-format="yyyy"
                        placeholder="选择日期">
                </rs-date-picker>
            </div>
            <echartsBra :id="id" :options="option" height="311px" :style="{width: '100%', height: '311px'}"></echartsBra>
        </div>
    </div>
</template>

<script>
    import echartsBra from '@/components/echarts/echarts_components'
    import Api from '@/api/reportServer'
    export default {
        name: "chart_block",
        components: {echartsBra},
        props:["id","mallCode","choose"],
        data(){
          return{
              option:'',
              year:'2020',
              title:'',
              radio:'电能'
          }
        },
        mounted(){
            this.getData()
        },
        methods:{
            chooseYear(){
                if(this.mallCode){
                    this.getData()
                }
            },
            chooseType(){
                if(this.mallCode){
                    this.getData()
                }
            },
            getData(){
                let params = {
                    mallCode:this.mallCode,
                    year:this.year
                }
                if(this.choose){
                    params = {
                        mallCode:this.mallCode,
                        year:this.year,
                        energyType:this.radio
                    }
                }
                Api.getReportData(this.id,params).then(res => {
                    this.title = res.data.data.metaData.title
                    this.option = {
                        dataset: {
                            dimensions:res.data.data.metaData.columnNames,
                            source: res.data.data.dataset
                        },
                        xAxis: {
                            type: 'category',
                            axisTick:{
                                show:false
                            }
                        },
                        yAxis: {
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed'
                                }
                            },
                            axisLine:{
                                show:false
                            },
                            axisTick:{
                                show:false
                            }
                        },
                        series: [
                            {
                                type: 'bar',
                                encode: {
                                    // Map the "amount" column to X axis.
                                    y: res.data.data.chartConfig.yAxis,
                                    // Map the "product" column to Y axis
                                    x: res.data.data.chartConfig.xAxis
                                },
                                barWidth: 30,
                                itemStyle:{
                                    normal:{
                                        color:'#1890FF'
                                    }
                                }
                            }
                        ],
                        grid: {
                            left: '8%',
                            right: '10px',
                            top: '20%',
                            bottom: '20%'
                        },
                        tooltip:{
                            show:true
                        }
                    };
                })
            },
        },
        watch:{
            mallCode(val){
                if(val){
                    this.getData()
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .contain {
        flex: 1;
    }
    .contain:first-child {
        margin-right: 14px;
    }
    .contain{
    .count-main {
    // padding-left: 28px;
        display: flex;
        height: calc(100% - 52px);
        align-items: center;
        padding: 10px;
        margin-top:5px;
        justify-content: space-around;
    // flex-direction: column;
    .flex {
        // flex: 1;
        cursor: pointer;
        flex-direction: column;
        min-width: 120px;
        .count-label {
            display: flex;
            align-items: center;
            // width: 146px;
            margin-top: 8px;
            .item-title {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #999999;
                text-align: center;
            }
        }
        .cost-value {
            font-family: PingFangSC-Medium;
            font-size: 28px;
            color: #333333;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            &:hover {
                color: #0091FF;
            }
        }
        .item-img-icon {
            max-height: 24px;
            margin-right: 10px;
        }
    }}}
    .count-title {
        height: 52px;
        text-align: left;
        line-height: 52px;
        padding-left: 20px;
        padding-right: 14px;
        border-bottom: 1.5px solid #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
    .flex-block{
        position: absolute;
        top:5px;
        left:10px;
        right:10px;
        z-index:9;
        display: flex;
        justify-content: space-between;
    }
</style>