var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "item-wrap-3",
      staticStyle: { "min-height": "200px", height: "100%" },
    },
    [
      _c("div", { staticClass: "count-title" }, [
        _vm._v("商户欠款"),
        _vm.isReady
          ? _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("（" + _vm._s(_vm.params.label) + "）"),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "count-main" },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "flex", on: { click: _vm.handleJump } },
            [
              _c(
                "div",
                {
                  staticClass: "cost-value",
                  staticStyle: { "margin-top": "30px" },
                },
                [_vm._v(_vm._s(item[1] ? _vm.$moneyShow(item[1]) : 0))]
              ),
              _c("div", { staticClass: "count-label" }, [
                _c("span", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(item[0])),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }