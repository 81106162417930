var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-wrap-3" },
    [
      _c(
        "rs-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("rs-tab-pane", {
            attrs: { label: "本月用水量（m³）", name: "水能" },
          }),
          _c("rs-tab-pane", {
            attrs: { label: "本月用电量（kwh）", name: "电能" },
          }),
          _c("rs-tab-pane", {
            attrs: { label: "本月燃气量（m³）", name: "天燃气" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "count-main" },
        _vm._l(_vm.data, function (item) {
          return _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "count_title" }, [
              _vm._v(_vm._s(item[0])),
            ]),
            _c("div", { staticClass: "cost-value" }, [
              _vm._v(_vm._s(_vm.$moneyShow(item[1]))),
            ]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }