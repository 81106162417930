<template>
    <div class="item-wrap-3" style="position: relative;margin-right:0;">
        <rs-tabs v-model="activeName" @tab-click="handleClick" >
            <rs-tab-pane label="招商铺位出租率" name="1">
            </rs-tab-pane>
            <rs-tab-pane label="多经铺位出租率" name="2">
            </rs-tab-pane>
            <rs-tab-pane label="广告位出租率" name="3">
            </rs-tab-pane>
        </rs-tabs>

        <rs-radio-group v-model="radio" size="mini" style="position: absolute;width:35%;top:75px;left:32%;z-index:9;" @change="onRadioChange">
            <rs-radio-button v-for="(item,index) in buildingList" :label="item.id" :key="index">{{item.buildingName}}</rs-radio-button>
        </rs-radio-group>
        <div style="display: flex;height:311px;position: relative;">
            <echartsBra id="32bf9e494d8b419b8b72617be1b9ebef" :options="option" height="311px" :style="{width: '30%', height: '311px', display: 'inlineBlock'}"></echartsBra>
            <div v-if="option2 &&  option2.dataset && option2.dataset.source.length === 0" class="noMsgIconDiv">
                <img src="../../assets/images/msgIcon/noMsgIcon.png">
                <span>暂无数据！</span>
            </div>
            <echartsBra v-else id="44a0b314d5fb4fcbb72c73c5df17e06e" :options="option2" height="311px" :style="{width: '70%', height: '311px', display: 'inlineBlock'}"></echartsBra>
        </div>
    </div>
</template>

<script>
    import echartsBra from '@/components/echarts/echarts_components'
    import Api from '@/api/reportServer'
    export default {
        name: "rent_rate_chart",
        components: {echartsBra},
        props:["mallCode"],
        data(){
            return{
                title:'',
                option:'',
                option2:'',
                activeName:"1",
                radio:'',
                buildingList:[]
            }
        },
        mounted(){
        },
        methods:{
            onRadioChange(val){
                this.option2 = {};
                this.getData();
            },
            getBuilding(){
                Api.getBuilding({mallCode:this.mallCode}).then(res => {
                    this.buildingList = res.data.data.buildingList
                    if(this.buildingList.length > 0){
                        this.radio = this.buildingList[0].id
                        this.getData()
                    }
                })
            },
            handleClick(){
                this.getBuilding()
            },
            getData(){
                if(this.activeName == '1'){
                    Api.getReportData('32bf9e494d8b419b8b72617be1b9ebef',{mallCode:this.mallCode,boothType:this.activeName}).then(res => {
                        this.title = res.data.data.metaData.title
                        this.option = {
                            dataset: {
                                dimensions:res.data.data.metaData.columnNames,
                                source: res.data.data.dataset
                            },
                            tooltip: {
                            },
                            legend: {
                            },
                            //grid: {containLabel: true},
                            series: [
                                {
                                    type: 'pie',
                                    id: 'pie',

                                    radius: ['40%', '60%'],
                                    center: ['50%', '48%'],
                                    encode: {
                                        itemName: res.data.data.chartConfig.xAxis,
                                        value: res.data.data.chartConfig.yAxis
                                    },
                                    label: {

                                    },
                                }
                            ],
                            color:['#27A1FF','#54D2D2', '#E8E8E8'],
                        };
                    })
                } else if(this.activeName=='3'){
                    Api.getReportData('1881ecd9d50c4ee3a18023c74285c9b9',{mallCode:this.mallCode}).then(res => {
                        this.title = res.data.data.metaData.title
                        this.option = {
                            dataset: {
                                dimensions:res.data.data.metaData.columnNames,
                                source: res.data.data.dataset
                            },
                            tooltip: {
                            },
                            legend: {
                            },
                            //grid: {containLabel: true},
                            series: [
                                {
                                    type: 'pie',
                                    id: 'pie',

                                    radius: ['40%', '60%'],
                                    center: ['50%', '48%'],
                                    encode: {
                                        itemName: res.data.data.chartConfig.xAxis,
                                        value: res.data.data.chartConfig.yAxis
                                    },
                                    label: {

                                    },
                                }
                            ],
                            color:['#27A1FF','#54D2D2', '#E8E8E8'],
                        };
                    })
                }else {
                    Api.getReportData('8e230d17bf9348afa84a584d471d82ae',{mallCode:this.mallCode}).then(res => {

                        this.title = res.data.data.metaData.title
                        this.option = {
                            dataset: {
                                dimensions:res.data.data.metaData.columnNames,
                                source: res.data.data.dataset
                            },
                            tooltip: {
                            },
                            legend: {
                            },
                            //grid: {containLabel: true},
                            series: [
                                {
                                    type: 'pie',
                                    id: 'pie',

                                    radius: ['40%', '60%'],
                                    center: ['50%', '48%'],
                                    encode: {
                                        itemName: res.data.data.chartConfig.xAxis,
                                        value: res.data.data.chartConfig.yAxis
                                    },
                                    label: {

                                    },
                                }
                            ],
                            color:['#27A1FF','#54D2D2', '#E8E8E8'],
                        };
                    })
                }

                let id
                if(this.activeName == '1'){
                    id = '44a0b314d5fb4fcbb72c73c5df17e06e'
                } else if(this.activeName == '2') {
                    id = '260d9410ab544a59baa0ba2dd11afa2d'
                } else if(this.activeName == '3') {
                    id = '789c820e30934c52bd651f7c3fbf571d'
                }
                Api.getReportData(id,{mallCode:this.mallCode,boothType:this.activeName,buildingId:this.radio}).then(res => {
                    let temp = [],dimensions=['statusDesc'],source=[];
                    res.data.data.dataset.forEach(item=>{
                        let key = item[2]
                        if(temp[key]==undefined){
                            temp[key] = []
                            dimensions.push(key)
                        }
                        temp[key][0] = key;
                        if(item[0]=='已租')
                            temp[key][2] = item[3];
                        else
                            temp[key][1] = item[3];
                    })
                    for(let key in temp){
                        source.push(temp[key]);
                    }

                    if(source.length==0) return;

                    this.title = res.data.data.metaData.title
                    this.option2 = {
                        dataset: {
                            dimensions:dimensions,
                            source:source
                        },
                        xAxis: {
                            type: 'category',
                            axisTick:{
                                show:false
                            }
                        },
                        yAxis: {
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed'
                                }
                            },
                            axisLine:{
                                show:false
                            },

                            axisTick:{
                                show:false
                            }
                        },
                        series: [
                            {
                                name:'未租',
                                type: 'bar',
                                barWidth: 30,
                                itemStyle:{
                                    normal:{
                                        color:'#54D2D2'
                                    }
                                }
                            },
                            {
                                name:'已租',
                                type: 'bar',
                                barWidth: 30,
                                itemStyle:{
                                    normal:{
                                        color:'#1890FF'
                                    }
                                }
                            }
                        ],
                        grid: {
                            left: '8%',
                            right: '10px',
                            top: '20%',
                            bottom: '20%'
                        },
                        tooltip:{
                        },
                        legend: {
                            right:'10px'
                        },
                    };
                })
            },
        },
        watch:{
            mallCode:{
                handler(val){
                    if(val){
                        this.getData()
                        this.getBuilding()
                    }
                },
                immediate:true,
                deep:true
            }
        }
    }
</script>

<style lang="less" scoped>
    .item-wrap-3 {
        flex: 1;
    }
    .item-wrap-3:first-child {
        margin-right: 14px;
    }
    .item-wrap-3 {
        .count-main {
            // padding-left: 28px;
            display: flex;
            height: calc(100% - 52px);
            align-items: center;
            padding: 10px;
            margin-top:5px;
            justify-content: space-around;
            // flex-direction: column;
            .flex {
                // flex: 1;
                cursor: pointer;
                flex-direction: column;
                min-width: 120px;
                .count-label {
                    display: flex;
                    align-items: center;
                    // width: 146px;
                    margin-top: 8px;
                    .item-title {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #999999;
                        text-align: center;
                    }
                }
                .cost-value {
                    font-family: PingFangSC-Medium;
                    font-size: 28px;
                    color: #333333;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    &:hover {
                        color: #0091FF;
                    }
                }
                .item-img-icon {
                    max-height: 24px;
                    margin-right: 10px;
                }
            }}}
    .count-title {
        height: 52px;
        text-align: left;
        line-height: 52px;
        padding-left: 20px;
        padding-right: 14px;
        border-bottom: 1.5px solid #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
    .noMsgIconDiv {
        height: 250px;
        width:70%;
        position: relative;
    }
    .noMsgIconDiv img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150px;
        height: 150px;
        transform: translate(-50%, -50%);
    }
    .noMsgIconDiv span {
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        letter-spacing: 0;
        text-align: center;
    }
</style>
