var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page-wrap" },
    [
      _c(
        "rs-select",
        {
          staticClass: "select_width",
          staticStyle: { width: "300px", "margin-bottom": "10px" },
          attrs: { placeholder: "请选择" },
          model: {
            value: _vm.mallCode,
            callback: function ($$v) {
              _vm.mallCode = $$v
            },
            expression: "mallCode",
          },
        },
        _vm._l(_vm.mallList, function (item) {
          return _c("rs-option", {
            key: item.mallCode,
            attrs: { label: item.mallName, value: item.mallCode },
          })
        }),
        1
      ),
      _c("div", { staticClass: "flex-page" }, [
        _c("div", { staticStyle: { width: "57.6%" } }, [
          _c("div", { staticClass: "item-wrap-3" }, [
            _c("div", { staticClass: "count-title" }, [_vm._v("常用工具")]),
            _c("div", { staticClass: "count-main count-main-box" }, [
              _vm.btsResource["XZKH"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "客户管理",
                            "/investment/#/businessList"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjkh.png") },
                      }),
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v("新建客户"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XZPP"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "品牌管理",
                            "/investment/#/brandList"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjkh.png") },
                      }),
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v("新建品牌"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XZZSHT"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "合同管理",
                            "/contract/#/contractList"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjht.png") },
                      }),
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v("新建招商合同"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XZDJHT"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "多经合同管理",
                            "/contract/#/multiContractList"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjht.png") },
                      }),
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v("新建多经合同"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XJFY"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "费用管理",
                            "/finance/#/costOrderManage"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjsk.png") },
                      }),
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v("新建费用单"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XJSK（YS）"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "费用预收款",
                            "/finance/#/preCostReceiptManage"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjsk.png") },
                      }),
                      _vm._m(0),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XJSK（FY）"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "费用收款",
                            "/finance/#/costReceiptManage"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjsk.png") },
                      }),
                      _vm._m(1),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XJTK（YS）"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "费用预退款",
                            "/finance/#/costReceiptRefundManage"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjtk.png") },
                      }),
                      _vm._m(2),
                    ]
                  )
                : _vm._e(),
              _vm.btsResource["XJTK（FY）"]
                ? _c(
                    "div",
                    {
                      staticClass: "item item-1 item-hover1 bgchs",
                      on: {
                        click: function ($event) {
                          return _vm.handleJump(
                            "费用退款",
                            "/finance/#/feeRefundManage"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: { src: require("../../assets/images/xjtk.png") },
                      }),
                      _vm._m(3),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm.btsResource["ZSHTYJ"] || _vm.btsResource["DJHTYJ"]
            ? _c("div", { staticClass: "flex-box" }, [
                _vm.btsResource["ZSHTYJ"]
                  ? _c(
                      "div",
                      { staticClass: "item-wrap-3" },
                      [
                        _c("contract_chart", {
                          ref: "ContractEchart",
                          attrs: {
                            mallCode: _vm.mallCode,
                            id: "07b82bdaa2804c21a30462a92085d670",
                            params: _vm.warnData.contract,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.btsResource["DJHTYJ"]
                  ? _c(
                      "div",
                      { staticClass: "item-wrap-3" },
                      [
                        _c("contract_chart", {
                          ref: "MultyContractEchart",
                          attrs: {
                            mallCode: _vm.mallCode,
                            id: "00e3819c66584ccda5060ed6d87e7272",
                            params: _vm.warnData.multicontract,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.btsResource["GGWHTYJ"]
            ? _c("div", { staticClass: "flex-box" }, [
                _c(
                  "div",
                  { staticClass: "item-wrap-3" },
                  [
                    _c("contract_chart", {
                      ref: "advertise",
                      attrs: {
                        mallCode: _vm.mallCode,
                        id: "f4e2755396404bafb64eaaf6d37a4191",
                        params: _vm.warnData.multicontract,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.btsResource["SPQK"] || _vm.btsResource["SXGG"]
            ? _c(
                "div",
                { staticClass: "flex-box" },
                [
                  _vm.btsResource["SPQK"]
                    ? _c("repay_chart", {
                        ref: "repayEchart",
                        attrs: {
                          mallCode: _vm.mallCode,
                          params: _vm.warnData.paychat,
                          id: "d5f6a5d0c99243c4be772fe5b3d49c58",
                        },
                      })
                    : _vm._e(),
                  _vm.btsResource["SXGG"]
                    ? _c("notice_list", {
                        ref: "noticeList",
                        attrs: { mallCode: _vm.mallCode },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticStyle: { width: "41.2%", height: "843px" },
            attrs: { span: 10 },
          },
          [
            _c(
              "div",
              { staticClass: "item-wrap-3", staticStyle: { height: "100%" } },
              [
                _c(
                  "div",
                  { staticClass: "count-title flex-audit" },
                  [
                    _c("span", [
                      _vm._v("审批小助手 "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.flowTotal > 0,
                              expression: "flowTotal > 0",
                            },
                          ],
                        },
                        [_vm._v("· " + _vm._s(_vm.flowTotal))]
                      ),
                    ]),
                    _c(
                      "rs-button",
                      {
                        staticClass: "mini-button-click",
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleJump(
                              "审批中心",
                              "/workflow/#/workflowCenter"
                            )
                          },
                        },
                      },
                      [_vm._v("进入审批中心")]
                    ),
                  ],
                  1
                ),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.loadFlowData,
                        expression: "loadFlowData",
                      },
                    ],
                    staticClass: "infinite-list",
                    staticStyle: { overflow: "auto" },
                    attrs: {
                      "infinite-scroll-distance": 50,
                      "infinite-scroll-disabled": false,
                      "infinite-scroll-immediate": false,
                    },
                  },
                  [
                    _vm._l(_vm.flowList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "audit--container-box",
                          on: {
                            click: function ($event) {
                              return _vm.handleJump(
                                "审批详情",
                                `/workflow/#/flowDetail?processInstanceId=${item.processInstanceId}&&isBack=true`
                              )
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "flex-audit" }, [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { width: "73%" },
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.createPhotoUrl,
                                      expression: "item.createPhotoUrl",
                                    },
                                  ],
                                  staticClass: "user-image",
                                  attrs: { src: item.createPhotoUrl, alt: "" },
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !item.createPhotoUrl,
                                        expression: "!item.createPhotoUrl",
                                      },
                                    ],
                                    staticClass: "user-image",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item.createBy.length > 2
                                            ? item.createBy.substring(
                                                item.createBy.length - 2
                                              )
                                            : item.createBy
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "nowrap-text" }, [
                                  _c("p", { staticClass: "user-name" }, [
                                    _vm._v(_vm._s(item.createBy)),
                                  ]),
                                  item.classifyId !== 8
                                    ? _c(
                                        "p",
                                        { staticClass: "audit-text-num" },
                                        [_vm._v(_vm._s(item.approver))]
                                      )
                                    : _vm._e(),
                                  item.classifyId === 8
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "audit-text-num mallName nowrap-text",
                                        },
                                        [
                                          _vm._v(
                                            "商铺·" + _vm._s(item.shopName)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _c("span", { staticClass: "audit-time" }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                          ]),
                          _c("p", { staticClass: "title ml44" }, [
                            _vm._v(_vm._s(item.flowName)),
                          ]),
                          _c(
                            "p",
                            { staticClass: "audit-text-num audit-nowrap ml44" },
                            [_vm._v("审批编号：" + _vm._s(item.flowNo))]
                          ),
                          _c(
                            "rs-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "light",
                                content: `待${item.approver}审批`,
                                placement: "bottom-start",
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "audit-text-num audit-nowrap ml44",
                                },
                                [
                                  _vm._v(
                                    "当前进度：待" +
                                      _vm._s(item.approver) +
                                      "审批"
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.recordType == 10,
                                  expression: "item.recordType==10",
                                },
                              ],
                              staticClass: "audit-status sqz",
                            },
                            [_vm._v("申请中")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.recordType == 20,
                                  expression: "item.recordType==20",
                                },
                              ],
                              staticClass: "audit-status dwsp",
                            },
                            [_vm._v("待我审批")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.recordType == 30,
                                  expression: "item.recordType==30",
                                },
                              ],
                              staticClass: "audit-status wdcs",
                            },
                            [_vm._v("未读抄送")]
                          ),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.flowList.length === 0,
                            expression: "flowList.length === 0",
                          },
                        ],
                        staticClass: "noMsgIconDiv",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/msgIcon/noMsgIcon.png"),
                          },
                        }),
                        _c("span", [_vm._v("暂无数据！")]),
                      ]
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm.btsResource["XSKB"]
        ? _c(
            "div",
            { staticClass: "flex-box", staticStyle: { height: "360px" } },
            [
              _c(
                "div",
                { staticClass: "item-wrap-3" },
                [_c("sales_chart", { attrs: { mallCode: _vm.mallCode } })],
                1
              ),
              _c(
                "div",
                { staticClass: "item-wrap-3" },
                [
                  _c("sale-chart", {
                    attrs: {
                      id: "ff6e16361cd145c7896e8eafec515592",
                      mallCode: _vm.mallCode,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.btsResource["CZLKB"]
        ? _c(
            "div",
            { staticClass: "flex-box", staticStyle: { height: "360px" } },
            [_c("rent_rate_chart", { attrs: { mallCode: _vm.mallCode } })],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "item-wrap-3" }, [
          _c("div", { staticClass: "count-title" }, [
            _vm._v("\n        流量\n        "),
            _c(
              "span",
              {
                staticStyle: {
                  color: "#ff0000",
                  "font-size": "14px",
                  "margin-left": "10px",
                },
              },
              [_vm._v("今日总流量（" + _vm._s(_vm.todayVal) + "）")]
            ),
          ]),
          _c("div", { staticClass: "count-main" }, [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "cost-value" }, [
                _vm._v(_vm._s(_vm.passengerFlow.count)),
              ]),
              _vm._m(4),
            ]),
          ]),
        ]),
      ]),
      _vm.btsResource["NYKB"]
        ? _c(
            "div",
            { staticClass: "flex-box", staticStyle: { height: "360px" } },
            [
              _c("card_chart", { attrs: { mallCode: _vm.mallCode } }),
              _c(
                "div",
                { staticClass: "item-wrap-3" },
                [
                  _c("sale-chart", {
                    attrs: {
                      id: "acf6b5ca7b2f45a48b6eff849a1dccc6",
                      choose: "true",
                      mallCode: _vm.mallCode,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "item-title" }, [
      _vm._v("新建收款"),
      _c("br"),
      _vm._v("（预收款）"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "item-title" }, [
      _vm._v("新建收款"),
      _c("br"),
      _vm._v("（费用收款）"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "item-title" }, [
      _vm._v("新建退款"),
      _c("br"),
      _vm._v("（预收款）"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "item-title" }, [
      _vm._v("新建退款"),
      _c("br"),
      _vm._v("（费用退款）"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "count-label" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("客流量（人次）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }