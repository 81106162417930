<template>
    <div class="item-wrap-3" style="min-height:200px;height:100%;">
        <div class="count-title">商户欠款<span style="font-size:14px;" v-if="isReady">（{{params.label}}）</span></div>
        <div class="count-main">
            <div class="flex" v-for="(item,index) in data" @click="handleJump" :key="index">
                <!--<div class="count_title">{{item[0]}}</div>-->
                <div class="cost-value" style="margin-top:30px;">{{item[1]?$moneyShow(item[1]):0}}</div>
                <div class="count-label"><span class="item-title">{{item[0]}}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/api/reportServer'
    export default {
        name: "card_chart.vue",
        props:['mallCode',"id","params","label"],
        data(){
            return{
                data:[],
                title:'',
                isReady:false
            }
        },
        mounted(){

        },
        methods:{
            getData(){
                this.isReady = true;
                Api.getReportData(this.id,{mallCode:this.mallCode,...this.params}).then(res => {
                    this.title = res.data.data.metaData.title
                    this.data = res.data.data.dataset
                })
            },
            handleClick(){
                this.getData()
            },
            handleJump(name){
                let url = this.params.label=='截止到今日'   ? `/finance/#/costOrderManage?mallCode=${this.mallCode}`   :`/finance/#/costOrderManage?mallCode=${this.mallCode}&beginTime=${this.params.beginTime}&endTime=${this.params.endTime}`  
                let params = {
                    type :'jumpUrl',
                    url:'费用管理'+'&' +url
                }
                parent.postMessage(params, '*')
            },
        }
    }
</script>

<style lang="less" scoped>
    .item-wrap-3 {
        flex: 1;
        height:100%;
    }
    .item-wrap-3 {
        .count-main {
            // padding-left: 28px;
            height:147px;
            .flex {
                // flex: 1;
                text-align:center;
                cursor: pointer;
                width:50%;
                height:100%;
                padding: 10px;
                min-height: 147px;
                float: left;
                min-width: 120px;
                .count-label {
                    text-align:center;
                    margin-top:8px;
                    // width: 146px;
                    .item-title {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #999999;
                        text-align: center;
                    }
                }
                .cost-value {
                    font-family: PingFangSC-Medium;
                    font-size: 28px;
                    color: #333333;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    &:hover {
                        color: #0091FF;
                    }
                }
                .item-img-icon {
                    max-height: 24px;
                    margin-right: 10px;
                }

            }
            &:hover{
                background:  rgba(243,251,255,0.50);                    
            }
        }
    }
    .count-title {
        height: 52px;
        text-align: left;
        line-height: 52px;
        padding-left: 20px;
        padding-right: 14px;
        border-bottom: 1.5px solid #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
    .count_title {
        text-align: center;
        line-height: 35px;
        padding-left: 20px;
        padding-right: 14px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #3B4859;
        letter-spacing: 0;
    }
</style>