<template>
  <section class='page-wrap'>
    <rs-select class="select_width" v-model="mallCode" placeholder="请选择" style="width:300px;margin-bottom:10px;">
      <rs-option v-for="item in mallList" :key="item.mallCode" :label="item.mallName" :value="item.mallCode">
      </rs-option>
    </rs-select>
    <div class="flex-page">
      <div style="width: 57.6%;">
        <div class="item-wrap-3">
          <div class="count-title">常用工具</div>
          <div class="count-main count-main-box">
            <!--<div class="item item-1 item-hover1 bgchs">-->
            <!--<img class="item-img" src="../../assets/images/xjxs.png">-->
            <!--<span class="item-title">新建线索</span>-->
            <!--</div>-->
            <div v-if="btsResource['XZKH']" class="item item-1 item-hover1 bgchs" @click="handleJump('客户管理','/investment/#/businessList')">
              <img class="item-img" src="../../assets/images/xjkh.png">
              <span class="item-title">新建客户</span>
            </div>
            <div v-if="btsResource['XZPP']" class="item item-1 item-hover1 bgchs" @click="handleJump('品牌管理','/investment/#/brandList')">
              <img class="item-img" src="../../assets/images/xjkh.png">
              <span class="item-title">新建品牌</span>
            </div>
            <div v-if="btsResource['XZZSHT']" class="item item-1 item-hover1 bgchs" @click="handleJump('合同管理','/contract/#/contractList')">
              <img class="item-img" src="../../assets/images/xjht.png">
              <span class="item-title">新建招商合同</span>
            </div>
            <div v-if="btsResource['XZDJHT']" class="item item-1 item-hover1 bgchs" @click="handleJump('多经合同管理','/contract/#/multiContractList')">
              <img class="item-img" src="../../assets/images/xjht.png">
              <span class="item-title">新建多经合同</span>
            </div>
            <div v-if="btsResource['XJFY']" class="item item-1 item-hover1 bgchs" @click="handleJump('费用管理','/finance/#/costOrderManage')">
              <img class="item-img" src="../../assets/images/xjsk.png">
              <span class="item-title">新建费用单</span>
            </div>
            <div v-if="btsResource['XJSK（YS）']" class="item item-1 item-hover1 bgchs" @click="handleJump('费用预收款','/finance/#/preCostReceiptManage')">
              <img class="item-img" src="../../assets/images/xjsk.png">
              <span class="item-title">新建收款<br>（预收款）</span>
            </div>
            <div v-if="btsResource['XJSK（FY）']" class="item item-1 item-hover1 bgchs" @click="handleJump('费用收款','/finance/#/costReceiptManage')">
              <img class="item-img" src="../../assets/images/xjsk.png">
              <span class="item-title">新建收款<br>（费用收款）</span>
            </div>
            <div v-if="btsResource['XJTK（YS）']" class="item item-1 item-hover1 bgchs" @click="handleJump('费用预退款','/finance/#/costReceiptRefundManage')">
              <img class="item-img" src="../../assets/images/xjtk.png">
              <span class="item-title">新建退款<br>（预收款）</span>
            </div>
            <div v-if="btsResource['XJTK（FY）']" class="item item-1 item-hover1 bgchs" @click="handleJump('费用退款','/finance/#/feeRefundManage')">
              <img class="item-img" src="../../assets/images/xjtk.png">
              <span class="item-title">新建退款<br>（费用退款）</span>
            </div>
          </div>
        </div>
        <div class="flex-box" v-if="btsResource['ZSHTYJ'] || btsResource['DJHTYJ']">
          <div class="item-wrap-3" v-if="btsResource['ZSHTYJ']">
            <contract_chart ref="ContractEchart" :mallCode="mallCode" id="07b82bdaa2804c21a30462a92085d670" :params="warnData.contract"></contract_chart>
          </div>
          <div class="item-wrap-3" v-if="btsResource['DJHTYJ']">
            <contract_chart ref="MultyContractEchart" :mallCode="mallCode" id="00e3819c66584ccda5060ed6d87e7272" :params="warnData.multicontract"></contract_chart>
          </div>
        </div>
        <div class="flex-box" v-if="btsResource['GGWHTYJ']">
          <div class="item-wrap-3">
            <contract_chart ref="advertise" :mallCode="mallCode" id="f4e2755396404bafb64eaaf6d37a4191" :params="warnData.multicontract"></contract_chart>
          </div>
        </div>
        <div class="flex-box" v-if="btsResource['SPQK'] || btsResource['SXGG']">
          <repay_chart v-if="btsResource['SPQK']" ref="repayEchart" :mallCode="mallCode" :params="warnData.paychat" id="d5f6a5d0c99243c4be772fe5b3d49c58"></repay_chart>
          <notice_list v-if="btsResource['SXGG']" ref="noticeList" :mallCode="mallCode"></notice_list>
        </div>
      </div>
      <div :span="10" style="width: 41.2%; height:843px;">
        <div class="item-wrap-3" style="height:100%;">
          <div class="count-title flex-audit">
            <span>审批小助手 <span v-show="flowTotal > 0">· {{flowTotal}}</span> </span>
            <rs-button type="text" @click="handleJump('审批中心','/workflow/#/workflowCenter')" size="mini" class="mini-button-click">进入审批中心</rs-button>
          </div>
          <ul class="infinite-list" :infinite-scroll-distance="50" v-infinite-scroll="loadFlowData" style="overflow:auto" :infinite-scroll-disabled="false" :infinite-scroll-immediate="false">
            <li class="audit--container-box" v-for="(item, index) in flowList" :key="index" @click="handleJump('审批详情',`/workflow/#/flowDetail?processInstanceId=${item.processInstanceId}&&isBack=true`)">
              <div class="flex-audit">
                <div class="flex" style="width: 73%;">
                  <img class="user-image" v-show="item.createPhotoUrl" :src="item.createPhotoUrl" alt="">
                  <div class="user-image" v-show="!item.createPhotoUrl">
                    {{item.createBy.length>2?item.createBy.substring(item.createBy.length-2):item.createBy}}
                  </div>
                  <div class="nowrap-text">
                    <p class="user-name">{{item.createBy}}</p>
                    <p class="audit-text-num" v-if="item.classifyId!==8">{{item.approver}}</p>
                    <p class="audit-text-num mallName nowrap-text" v-if="item.classifyId===8">商铺·{{item.shopName}}</p>
                  </div>
                </div>
                <span class="audit-time">{{item.createTime}}</span>
              </div>
              <!-- <el-tooltip class="item" effect="light" :content="item.flowName" placement="top-start"> -->
              <p class="title ml44">{{item.flowName}}</p>
              <!-- </el-tooltip> -->
              <p class="audit-text-num audit-nowrap ml44">审批编号：{{item.flowNo}}</p>
              <rs-tooltip class="item" effect="light" :content="`待${item.approver}审批`" placement="bottom-start">
                <p class="audit-text-num audit-nowrap ml44">当前进度：待{{item.approver}}审批</p>
              </rs-tooltip>
              <span class="audit-status sqz" v-show="item.recordType==10">申请中</span>
              <span class="audit-status dwsp" v-show="item.recordType==20">待我审批</span>
              <span class="audit-status wdcs" v-show="item.recordType==30">未读抄送</span>
            </li>
            <div v-show="flowList.length === 0" class="noMsgIconDiv">
              <img src="../../assets/images/msgIcon/noMsgIcon.png">
              <span>暂无数据！</span>
            </div>
          </ul>

        </div>
      </div>
    </div>
    <div class="flex-box" style="height:360px;" v-if="btsResource['XSKB']">
      <div class="item-wrap-3">
        <sales_chart :mallCode="mallCode"></sales_chart>
      </div>
      <div class="item-wrap-3">
        <sale-chart id="ff6e16361cd145c7896e8eafec515592" :mallCode="mallCode"></sale-chart>
      </div>
    </div>
    <div class="flex-box" style="height:360px;" v-if="btsResource['CZLKB']">
      <rent_rate_chart :mallCode="mallCode"></rent_rate_chart>
    </div>
    <div class="flex-box">
      <div class="item-wrap-3">
        <div class="count-title">
          流量
          <span style="color:#ff0000;font-size:14px;margin-left:10px;">今日总流量（{{todayVal}}）</span>
          <!-- <span style="font-size: 14px;color: #1890FF; float:right;cursor: pointer">查看详情</span> -->
        </div>
        <div class="count-main">
          <div class="flex">
            <div class="cost-value">{{passengerFlow.count}}</div>
            <div class="count-label"><span class="item-title">客流量（人次）</span></div>
          </div>
          <!-- <div class="flex" >
                        <div class="cost-value">1200</div>
                        <div class="count-label"><span class="item-title">车流量（辆）</span></div>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="flex-box" style="height:360px;" v-if="btsResource['NYKB']">
      <card_chart :mallCode="mallCode"></card_chart>
      <div class="item-wrap-3">
        <sale-chart id="acf6b5ca7b2f45a48b6eff849a1dccc6" choose="true" :mallCode="mallCode"></sale-chart>
      </div>
      <!--<div class="item-wrap-3">-->
      <!--<div class="count-title">能源使用明细</div>-->
      <!--<div class="count-main" style="position: relative;">-->
      <!--<rs-radio-group v-model="radio3" size="mini" style="position: absolute;width:35%;top:5px;left:10px;z-index:9;">-->
      <!--<rs-radio-button label="电能"></rs-radio-button>-->
      <!--<rs-radio-button label="水能"></rs-radio-button>-->
      <!--<rs-radio-button label="燃气"></rs-radio-button>-->
      <!--</rs-radio-group>-->
      <!--<rs-date-picker-->
      <!--size="mini"-->
      <!--style="position: absolute;width:35%;top:5px;right:10px;z-index:9;"-->
      <!--v-model="value1"-->
      <!--type="date"-->
      <!--placeholder="选择日期">-->
      <!--</rs-date-picker>-->
      <!--<div id="myChart05" :style="{width: '100%', height: '311px'}"></div>-->
      <!--</div>-->
      <!--</div>-->
    </div>
    <!-- <div class="flex-box" style="height:360px;">
            <div class="item-wrap-3" style="position: relative;margin-right:0;">
                <div class="count-title">会员情况</div>
                <rs-date-picker
                        size="mini"
                        style="position: absolute;width:25%;top:65px;left:10px;z-index:9;"
                        v-model="value1"
                        type="date"
                        placeholder="选择日期">
                </rs-date-picker>
                <div class="count-main" style="height:auto;margin-top:30px;">
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">新增会员数</div>
                        <div class="cost-value">10</div>
                    </div>
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">活跃会员数</div>
                        <div class="cost-value">100</div>
                    </div>
                    <div class="flex"  style="min-width:200px">
                        <div class="count_title">会员消费金额（元）</div>
                        <div class="cost-value">{{$moneyShow(1000)}}</div>
                    </div>
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">会员消费金额（元）</div>
                        <div class="cost-value">{{$moneyShow(102000)}}</div>
                    </div>
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">会员消费金额（元）</div>
                        <div class="cost-value">{{$moneyShow(20)}}</div>
                    </div>
                </div>
                <div class="count-main" style="height:auto">
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">累计会员数</div>
                        <div class="cost-value">10</div>
                    </div>
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">活跃率</div>
                        <div class="cost-value">100</div>
                    </div>
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">会员消费占比</div>
                        <div class="cost-value">12.23%</div>
                    </div>
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">积分消耗率</div>
                        <div class="cost-value">3.24%</div>
                    </div>
                    <div class="flex" style="min-width:200px">
                        <div class="count_title">累计积分量</div>
                        <div class="cost-value">{{$moneyShow(20)}}</div>
                    </div>
                </div>

            </div>
        </div> -->
    <!-- <div class="flex-box" style="height:360px;">
            <div class="item-wrap-3" style="position: relative;margin-right:0;">
                <rs-tabs v-model="activeName04" @tab-click="handleClick" >
                    <rs-tab-pane label="新增会员" name="first">
                    </rs-tab-pane>
                    <rs-tab-pane label="活跃会员" name="second">
                    </rs-tab-pane>
                    <rs-tab-pane label="会员消费" name="third">
                    </rs-tab-pane>
                    <rs-tab-pane label="积分变动情况" name="fourth">
                    </rs-tab-pane>
                </rs-tabs>
                <rs-date-picker
                        size="mini"
                        style="position: absolute;width:25%;top:75px;left:10px;z-index:9;"
                        v-model="value1"
                        type="date"
                        placeholder="选择日期">
                </rs-date-picker>
                <div id="myChart06" :style="{width: '100%', height: '311px'}"></div>
            </div>
        </div> -->
    <!-- <div class="flex-box" style="height:360px;">
            <div class="item-wrap-3">
                <div class="count-title">月度销售数据</div>
                <div class="count-main" style="position: relative;">
                    <div style="display: flex;width:100%;align-items: center; position: absolute;top:10px;">
                        <span style="width:120px;text-align:right">活动名称：</span>
                        <rs-select placeholder="请选择" style="display: inline-block;width:200px;"></rs-select>
                    </div>
                    <rs-form  label-width="140px" style="margin-top:40px;">
                        <rs-form-item label="活动时间">
                            <span>2020/01/01~2020/02/02</span>
                        </rs-form-item>
                        <rs-form-item label="活动类型">
                            <span>积分双倍赠送</span>
                        </rs-form-item>
                        <rs-form-item label="活动形式">
                            <span>积分发放</span>
                        </rs-form-item>
                        <rs-form-item label="积分发送数量">
                            <span>20000</span>
                        </rs-form-item>
                        <rs-form-item label="积分核销数量">
                            <span>20000</span>
                        </rs-form-item>
                        <rs-form-item label="参与店铺数">
                            <span>100</span>
                        </rs-form-item>
                    </rs-form>
                </div>
            </div>
            <div class="item-wrap-3">
                <rs-tabs v-model="activeName" @tab-click="handleClick" >
                    <rs-tab-pane label="今日销售排行" name="first">

                    </rs-tab-pane>
                    <rs-tab-pane label="累计销售排行" name="second">
                    </rs-tab-pane>
                    <div id="myChart07" :style="{width: '100%', height: '311px'}"></div>
                </rs-tabs>
            </div>
        </div> -->
  </section>
</template>

<script>
import Utils from '@/utils/utils'
import { setTimeout } from 'timers';
import moment from 'moment'
import { mapGetters } from 'vuex'
import Api from '@/api/reportServer'
import Api1 from '@/api/api'
import echartsBra from '@/components/echarts/echarts_components'
import saleChart from '@/components/echarts/sale_chart'
import Rent_rate_chart from "../../components/echarts/rent_rate_chart";
import card_chart from '@/components/echarts/card_chart'
import contract_chart from '@/components/echarts/contract_chart'
import sales_chart from '@/components/echarts/sales_chart.vue'
import repay_chart from '@/components/echarts/repay_chart.vue'
import notice_list from '@/components/echarts/notice_list.vue'
import Http from '@/api/api'
export default {
  name: "Index",
  components: { Rent_rate_chart, echartsBra, saleChart, card_chart, contract_chart, sales_chart, repay_chart, notice_list },
  computed: {
  },
  watch: {
    mallCode() {
      this.getWarnManage();
    }
  },
  data() {
    return {
      todayVal: moment().format('YYYY年MM月DD日'),
      mallList: [],
      mallCode: '',
      option: '',
      option01: '',
      activeName: 'first',
      activeName02: 'first',
      activeName03: 'first',
      activeName04: 'first',
      radio1: '楼层',
      radio2: 'A区',
      radio3: '电能',
      chromeFlag: false,  // 非chrome浏览器提示框
      myChart: null,
      mallId: '',
      mallArr: [],
      expireTabActive: 'contTab1', // 合同tab
      enterTabActive: 'mallTab1', // 商户tab
      mulTabActive: 'mulTab1', // 综合的tab
      enterData: 0,
      expireData: 0,
      mulpData: {
        enter: 0,
        out: 0
      },
      rentData: {
        lease: 0,
        total: 0,
        notlet: 0,
      },
      arrearAmount: 0, // 欠费金额
      arrearMallCount: 0,// 欠费商户数量
      saasMarket: null, // 当前选中的商场信息
      // 店铺各个状态数
      shopNum: {
        jczxNum: 0,
        kyNum: 0,
        kstcNum: 0,
        wctcNum: 0,
      },
      msgHeight: {
        'max-height': '646px'
      },
      expireNum: 0,
      overDue: {
        noPayShopNum: 0,
        totalNoPayAmt: 0
      },
      value1: '',
      flowList: [],
      flowPageNum: 1,
      flowTotal: 0,
      btsResource: [],
      warnData: {              //预警相关
        warnDateTypeList: [],                                                    //单位  {day: "天",month: "月",year: "年"}
        contract: { endDays: 0, beginDays: 0, boothType: 1, label: ['/', '/'] },                  //招商
        multicontract: { endDays: 0, beginDays: 0, boothType: 1, label: ['/', '/'] },             //多经
        paychat: { beginTime: '/', endTime: '', label: '截止到今日' }
      },
      passengerFlow: {}
    }
  },
  methods: {
    async getMall() {
      let user = Utils.getSession("userInfo");
      let mall = await Api.getMall({ 'userId': user.userId })
      this.mallList = mall.data.data
      if (this.mallList.length > 0) {
        this.mallCode = this.mallList[0].mallCode
        //this.getData()
        this.getPassengerFlow();
      }
    },
    handleJump(name, url) {
      let params = {
        type: 'jumpUrl',
        url: name + '&' + url
      }
      parent.postMessage(params, '*')
    },
    getFlow() {
      let user = Utils.getSession("userInfo");
      let postIdStr
      if (Utils.getSession('userInfo').stationLoginVoList) {
        Utils.getSession('userInfo').stationLoginVoList.forEach((item, index) => {
          index == 0 ? postIdStr = item.stationSysCode : postIdStr += ',' + item.stationSysCode
        })
      }
      Api.getworkflowToMe({
        userCode: user.userCode,
        postId: postIdStr,
        pageNum: this.flowPageNum,
        pageSize: 10,
        companyCode: user.companyVo ? user.companyVo.companyCode : ''
      }).then(res => {
        this.flowTotal = res.data.data.total
        this.flowList = res.data.data.list ? this.flowList.concat(res.data.data.list) : this.flowList.concat([])
        this.flowList = this.flowList.map(item => {
          item.createTime = moment(item.createTime).format("YYYY/MM/DD HH:mm")
          return item
        })
      })
    },
    loadFlowData() {
      if (Math.ceil(this.flowTotal / 10) >= this.flowPageNum + 1) {
        this.flowPageNum += 1
        this.getFlow()
      }
    },
    async getWarnManage() {      //预警看板
      let isGetType = false;
      for (let key in this.warnData.warnDateTypeList) {
        isGetType = true;
      }
      if (!isGetType) {
        let data = await Api1.getWarningconfigType({ dictType: "time_unit" });       //获取预警天数的单位:天、月、日等
        data.data.data.forEach(item => {
          this.warnData.warnDateTypeList[item.dictValue] = item.dictName
        })

      }
      let params = { "bizType": 1, mallCode: this.mallCode };
      let data = await Api1.queryWarinngList(params)
      data.data.data.list.forEach(item => {
        switch (item.configType) {
          case '招商合同到期预警':
            this.warnData.contract.endDays = this.getDay(item.configValue1, item.configValue);
            this.warnData.contract.label[0] = '未来' + item.configValue + this.warnData.warnDateTypeList[item.configValue1];
            break;
          case '招商合同开始提醒':
            this.warnData.contract.beginDays = this.getDay(item.configValue1, item.configValue);
            this.warnData.contract.label[1] = '未来' + item.configValue + this.warnData.warnDateTypeList[item.configValue1];
            break;
          case '多经合同到期预警':
            this.warnData.multicontract.endDays = this.getDay(item.configValue1, item.configValue);
            this.warnData.multicontract.label[0] = '未来' + item.configValue + this.warnData.warnDateTypeList[item.configValue1];
            break;
          case '多经合同开始提醒':
            this.warnData.multicontract.beginDays = this.getDay(item.configValue1, item.configValue);
            this.warnData.multicontract.label[1] = '未来' + item.configValue + this.warnData.warnDateTypeList[item.configValue1];
            break;
          case '商户欠款预警':
            this.warnData.paychat.beginTime = this.getTime(item.configValue1, item.configValue);
            this.warnData.paychat.endTime = this.getTime(0, 0);
            this.warnData.paychat.label = '自' + item.configValue + this.warnData.warnDateTypeList[item.configValue1] + '前截止到今日';
            break;
        }
      })
      if (this.$refs.ContractEchart) this.$refs.ContractEchart.getData();
      if (this.$refs.MultyContractEchart) this.$refs.MultyContractEchart.getData();
      if (this.$refs.advertise) this.$refs.advertise.getData();
      if (this.$refs.repayEchart) this.$refs.repayEchart.getData();
      if (this.$refs.noticeList) this.$refs.noticeList.getData();
    },
    getDay(type, val) {
      return type == 'day' ? val : type == 'month' ? val * 30 : val * 365
    },
    getTime(type, val) {
      let days = this.getDay(type, val);
      let begintime = new Date(Date.now() - days * 24 * 60 * 60 * 1000)
      let fixed = num => num < 10 ? '0' + num : num;
      return `${begintime.getFullYear()}-${fixed(begintime.getMonth() + 1)}-${fixed(begintime.getDate())}`
    },
    getPassengerFlow() {
      Api.getReportData('16942124a4d24c51899c148fbd43eb8e', {
        mallCode: this.mallCode
      }).then(res => {
        this.passengerFlow = {
          title: res.data.data.metaData.title,
          count: res.data.data.dataset[0][0]
        }
      })
    }
  },
  mounted() {
    Http.getButtonResource().then(res => {
        Utils.setSession('buttonResource',res.data.data)
        this.btsResource = Utils.buttonResource(['XZKH', 'XZPP', 'XZZSHT', 'XZDJHT', 'XJFY', 'SXGG', 'XJSK（YS）', 'NYKB', 'XJSK（FY）', 'XJTK（YS）', 'XJTK（FY）', 'ZSHTYJ', 'DJHTYJ', 'GGWHTYJ', 'SPQK', 'DBSX', 'XSKB', 'CZLKB']);
    })
    this.getMall()
    this.getFlow()
  }
}
</script>

<style scoped lang="less">
@theme-color-1: #44d7b6;
@theme-color-2: #35cbbb;
@theme-color-blue: #4c87ff;
@theme-white: #fff;
@theme-black: #000;
@text-main-color: #3b445b;
@bg-color: #fff;
@grey-light: #999;
@img-h: 56px;
@echarts-h: 211px;
@line-space-1: 20px;
@line-space-2: 20px;
@line-space-3: 10px;
@font-lg: 20px;
@font-md: 18px;
@font-sm: 16px;
@font-xs: 14px;
@select-width-1: 200px;
.page-wrap {
  // padding-top: 10px;
  min-width: 1156px;
  background-color: @bg-color;
  padding: 10px;
  color: @text-main-color;
  font-size: @font-sm;
  min-height: calc(100% - 40px);
  .flex-page {
    display: flex;
    justify-content: space-between;
  }
}
.unbusy-color {
  background-color: #e8e8e8;
}
.flex-audit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-box {
  display: flex;
  .item-wrap-3 {
    flex: 1;
  }
  .item-wrap-3:nth-child(2) {
    margin-left: 14px;
  }
  .item-wrap-3 {
    .count-main {
      // padding-left: 28px;
      display: flex;
      height: calc(100% - 52px);
      align-items: center;
      padding: 10px;
      justify-content: space-around;
      // flex-direction: column;
      .flex {
        // flex: 1;
        cursor: pointer;
        flex-direction: column;
        min-width: 120px;
        .count-label {
          display: flex;
          align-items: center;
          // width: 146px;
          margin-top: 8px;
          .item-title {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #999999;
            text-align: center;
          }
        }
        .cost-value {
          font-family: PingFangSC-Medium;
          font-size: 28px;
          color: #333333;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          &:hover {
            color: #0091ff;
          }
        }
        .item-img-icon {
          max-height: 24px;
          margin-right: 10px;
        }
      }
    }
  }
}
.count-main {
  overflow: hidden;
  min-height: 147px;
  .count-value {
    font-size: @font-lg;
    font-size: 30px;
    margin-bottom: 8px;
    color: #333333;
    cursor: pointer;
    font-weight: 600;
  }
  .count-value:hover {
    color: #0091ff;
  }
  .count-label {
    font-size: @font-xs;
    color: #444444;
  }
  ul {
    width: 100%;
  }
  ul > li {
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    .title {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      display: inline-block;
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .time {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #999999;
      display: inline-block;
      width: 30%;
    }
  }
}
.item-wrap-3 {
  overflow: hidden;
  border: 1px solid #e8ecef;
  box-shadow: 0 0 6px 0 #e3e4e4;
  margin-bottom: 16px;
  border-radius: 4px;
  background: @theme-white;
  .bgchs {
    float: left;
  }
  .item-hover1 {
    text-align: center;
    padding: 16px 2.7%;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    .item-img {
      max-width: @img-h;
      width: 56px;
      height: 56px;
    }
    .item-title {
      position: relative;
      color: #666666;
      font-size: @font-xs;
      margin-top: 14px;
    }
    &:hover {
      background: rgba(243, 251, 255, 0.5);
    }
  }
  .item-count {
    height: @echarts-h + 100;
    width: 32%;
  }
}
.def-img-wrap {
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  .img-inner-wrap {
    display: inline-block;
    line-height: normal;
    font-size: @font-xs;
    color: rgba(0, 0, 0, 0.25);
    p {
      margin-top: 10px;
    }
  }
  img {
    height: 100px;
  }
}
.count-title {
  height: 52px;
  text-align: left;
  line-height: 52px;
  padding-left: 20px;
  padding-right: 14px;
  border-bottom: 1.5px solid #f2f2f2;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #3b4859;
  letter-spacing: 0;
}
.count_title {
  text-align: left;
  line-height: 35px;
  padding-left: 20px;
  padding-right: 14px;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #3b4859;
  letter-spacing: 0;
}
.flex {
  display: flex;
  align-items: center;
}
.echarts {
  width: 100%;
  height: @echarts-h;
  margin-top: 25px;
}
.pull-layout {
  margin-top: 80px;
}
.status {
  font-size: 14px;
  line-height: 32px;
  height: 100%;
  width: 120px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 0;
  .circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .vertical-line {
    display: inline-block;
    margin: 0 8px;
  }
  .grey-f {
    color: #bababa;
  }
}
.blue {
  background-color: #26a1ff;
}
.green {
  background-color: #54d2d2;
}
.row-20 {
  width: 20%;
  float: left;
  text-align: center;
}
.row-20:first-child {
  border: 0;
}
.audit--container-box {
  margin: 14px 14px 4px;
  padding: 18px 14px 14px;
  background: #f7fcff;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.3);
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  .title {
    color: #333333;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0;
  }
  .user-image {
    height: 36px;
    width: 36px;
    line-height: 36px;
    background: rgb(24, 144, 255);
    border-radius: 50%;
    background: rgb(24, 144, 255);
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
  .user-name {
    color: #333333;
    font-size: 14px;
    line-height: 22px;
  }
  .audit-time {
    color: #333333;
    font-size: 12px;
    max-width: 27%;
  }
  .audit-text-num {
    color: #999999;
    font-size: 12px;
    line-height: 22px;
    text-align: left;
  }
  .mallName {
    color: #666666;
  }
  .nowrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .audit-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 96px;
  }
  .ml44 {
    margin-left: 44px;
  }
  .audit-status {
    position: absolute;
    right: 14px;
    bottom: 16px;
    font-size: 12px;
    height: 28px;
    padding: 0 10px;
    line-height: 28px;
    text-align: center;
  }
  .sqz {
    color: #1890ff;
    background: rgba(24, 144, 255, 0.1);
  }
  .dwsp {
    color: #ef8938;
    background: rgba(239, 137, 56, 0.1);
  }
  .wdcs {
    color: #36cbcb;
    background: rgba(54, 203, 203, 0.1);
  }
  &:hover {
    background: rgba(243, 251, 255, 0.5);
  }
}
.noMsgIconDiv {
  height: 400px;
  position: relative;
}
.noMsgIconDiv img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
}
.noMsgIconDiv span {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #999999;
  letter-spacing: 0;
  text-align: center;
}
.infinite-list {
  max-height: 770px;
  padding-bottom: 20px;
}
/* chrom提示框 */
.chroms-tips-main {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6vh;
}
.count-main-box {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  .item {
    min-width: 150px;
  }
}
</style>
<style lang="less">
@font-sm: 14px;
.item-tab {
  .el-tabs__item {
    font-size: @font-sm;
  }
}
</style>
<style lang="less">
// tab 切换未选中样式
@text-main-color: #3b445b;
.el-tabs__item {
  color: @text-main-color;
}
.page-wrap /deep/ .rs-tabs__nav-wrap {
  padding-left: 20px;
  height: 52px;
}
.page-wrap /deep/ .rs-tabs__item {
  height: 52px;
  line-height: 52px;
  font-size: 16px;
}
.page-wrap /deep/ .rs-form-item {
  margin-bottom: 0px;
}
.page-wrap /deep/ .rs-form-item__label,
.page-wrap /deep/ .rs-form-item__content {
  font-size: 16px;
}
</style>
